<template>
  <div>
    <div v-if="withLeading">
      <RecordMessageLeading :message="message" :user="user"></RecordMessageLeading>
    </div>
    <div
      v-if="(message.description.length && !message.is_private) || (message.is_private && !hidePrivateMessages)"
      class="rounded-xl p-3 mb-2 flex items-start gap-3 relative"
      :class="{
        'bg-yellow-500 bg-opacity-5': message.is_private,
        'bg-white': !message.is_private
      }"
    >
      <div v-if="isUnread" class="pt-2.5">
        <fw-dot color="primary" inline :ping="false" />
      </div>
      <div v-if="message.user_key && user" class="flex-shrink-0">
        <fw-avatar :user="user" :size="type === 'minimal' ? 'xs' : 'sm'" />
      </div>
      <div class="flex-1 flex flex-col gap-0.5">
        <div class="flex gap-3 items-center h-8">
          <div class="font-semibold flex-1">
            <v-clamp autoresize :max-lines="1">
              {{ user.name }}
            </v-clamp>
          </div>
          <div v-if="message.from_decision">
            <fw-tag size="xs" type="xlight">
              {{ fromDecisionLabel || $t('from_decision') }}
            </fw-tag>
          </div>
          <div v-if="showPublicPrivateToggle && message.is_private">
            <fw-tag size="xs" type="light-orange">
              <fw-icon-lock-line class="w-3 h-3 mr-1" /> {{ $t('private') }}
            </fw-tag>
          </div>
          <div class="text-xs">{{ message.created_date | formatDateTime }}</div>
          <div v-if="canEdit || canMarkAsRead">
            <b-dropdown aria-role="list" position="is-bottom-left">
              <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                <fw-icon-more class="w-5 h-5" />
              </fw-button-dropdown>
              <b-dropdown-item
                v-if="canEdit && loggedUser.key == message.user_key"
                aria-role="menuitem"
                @click="$emit('edit', message)"
              >
                {{ $t('edit') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canEdit && loggedUser.key == message.user_key"
                aria-role="menuitem"
                @click="$emit('delete', message)"
              >
                {{ $t('delete') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="canMarkAsRead" aria-role="menuitem" @click="$emit('read', message)">
                Marcar como lida
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="mb-2">
          <div class="message-content" :class="{ 'text-sm': type === 'minimal' }" v-html="message.description"></div>
        </div>
        <div v-if="message.files && message.files.length > 0">
          <fw-label>Ficheiros</fw-label>
          <div>
            <RecordFileEntry
              v-for="file in message.files"
              :key="file.key"
              paddingless
              class="mb-2"
              :allow-classified="false"
              :file="file"
              :can-edit="false"
              :can-remove="false"
              :open-modal="true"
              :download-from-title="false"
              @download="downloadFile(file)"
              @open="showPreviewModal = true"
            />
          </div>
          <div v-if="hasFileCopyListener && allowCopyFiles" class="flex justify-start">
            <fw-button type="link" paddingless @click.native="$emit('copy-files')">{{ copyFilesLabel }}</fw-button>
          </div>
        </div>
        <div v-if="$slots.footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
    <fw-modal
      v-if="showPreviewModal"
      :active.sync="showPreviewModal"
      :can-cancel="true"
      size="auto"
      width="auto"
      @close="closeModal"
    >
      <template #default>
        <PanelPreviewMedia
          v-for="file in message.files"
          :key="file.key"
          :title="file.filename"
          :file="file"
          :emit-download="true"
          class="mb-2"
          @download="downloadFile(file)"
        />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import RecordMessageLeading from '@/fw-modules/fw-core-vue/ui/components/records/RecordMessageLeading'
import PanelPreviewMedia from '../panels/PanelPreviewMedia.vue'

export default {
  components: {
    RecordFileEntry,
    RecordMessageLeading,
    PanelPreviewMedia
  },
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      }
    },
    user: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: 'normal'
    },
    showPublicPrivateToggle: {
      type: Boolean,
      default: true
    },
    // Make sure no private messages are visible, even if backend forgets to hide them...
    hidePrivateMessages: {
      type: Boolean,
      default: true
    },
    forcePrivateMessages: {
      type: Boolean,
      default: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    canMarkAsRead: {
      type: Boolean,
      default: false
    },
    withLeading: {
      type: Boolean,
      default: false
    },
    copyFilesLabel: {
      type: String,
      default: 'Copiar ficheiros'
    },
    allowCopyFiles: {
      type: Boolean,
      default: false
    },
    fromDecisionLabel: {
      type: String,
      default: 'Projeto de decisão / decisão'
    },
    isUnread: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return { showPreviewModal: false }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    hasFileCopyListener() {
      return this.$listeners && this.$listeners['copy-files']
    }
  },

  methods: {
    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    closeModal() {
      this.showPreviewModal = false
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "private": "Interna",
    "public": "Pública",
    "edit": "Editar",
    "delete": "Eliminar",
    "youAt": "You",
    "from_decision": "Projeto de decisão / decisão"
  },
  "en": {
    "private": "Internal",
    "public": "Public",
    "edit": "Edit",
    "delete": "Delete",
    "youAt": "You",
    "from_decision": "Decision project / decision"
  }
}
</i18n>
