<template>
  <div v-if="message.type == 'deny' || message.type == 'approve'" class="text-xs text-gray-500 text-center my-4">
    <span v-if="user" class="mr-1">{{ user.full_name }}</span>
    <span class="mr-1">{{ $t(`messageActions.${message.type}`) }}</span>
    <span>{{ $t('theJustification') }} {{ message.created_date | formatDateTime }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      }
    },
    user: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "theJustification": "a justificação",
    "private": "Privado",
    "public": "Público",
    "messageActions": {
      "approve": "aprovou",
      "deny": "rejeitou"
    }
  },
  "en": {
    "theJustification": "the justification",
    "private": "Private",
    "public": "Public",
    "messageActions": {
      "approve": "approved",
      "deny": "denied"
    }
  }
}
</i18n>
